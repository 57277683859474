export const useBlend = () => {

  const visible = useState('blendVisibilityMode', () => "none")
  const overlay = useState('blendOverlayMode', () => true)
  const dismissCallback = useState('blendDismissEvent', () => 'init')

  const showBlend = (onlyMobile = false, withTablet = false, headerOverlay = true) => {
    visible.value = onlyMobile ? (withTablet ? 'tablet' : 'mobile') : 'all'
    overlay.value = headerOverlay;
  }

  const hideBlend = () => {
    visible.value = "none"
    dismissCallback.value = 'init';
  }

  const blendEffect = (watchReference, onlyMobile = false, withTablet = false, headerOverlay = true, onDismiss = null) => {
    watchEffect(() => {
      if (watchReference.value) {
        showBlend(onlyMobile, withTablet, headerOverlay);
      } else {
        setTimeout(() => hideBlend(), 150);
      }
    })

    watchEffect(() => {
      if (onDismiss && typeof onDismiss === 'function' && dismissCallback.value !== 'init') {
        onDismiss(dismissCallback.value);
        dismissCallback.value = 'init';
      }
    })
  }

  const dismissBlend = () => {
    dismissCallback.value = 'dismiss'
  }

  return {
    showBlend,
    hideBlend,
    visible,
    overlay,
    blendEffect,
    dismissBlend
  }
}
